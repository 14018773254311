<template>
  <div class="prozess-wrapper">
    <div class="prozess">
      <div>
        <div v-if="!claimed">
          <TaskBlocker :assignee="data.assignee" />
        </div>
        <div v-if="!isLoading">
          <display-container label="ONT Details">
            <div class="details">
              <data-display label="Kundenangabe bei Bestellung">
                {{ ontId }}
              </data-display>
              <data-display label="varioResource">
                <template v-if="crmOnt === null">
                  <v-icon
                    class="mr-2"
                    color="red"
                    small
                  >
                    mdi-close
                  </v-icon>
                  <span>ONT Resource nicht vorhanden</span>
                </template>
                <template v-else>
                  <v-icon
                    class="mr-2"
                    color="green"
                    small
                  >
                    mdi-check
                  </v-icon>
                  <span>ONT Resource vorhanden</span>
                </template>
              </data-display>
            </div>
          </display-container>
          <display-container v-if="crmOnt !== null" label="ONT CRM">
            <div class="details">
              <data-display v-if="camundaAdmin" label="CRM ID">
                {{ crmOnt.serviceDataId }}
              </data-display>
              <data-display label="Hersteller">
                {{ crmOnt.vendor }}
              </data-display>
              <data-display label="Modell">
                {{ crmOnt.model }}
              </data-display>
            </div>
          </display-container>
          <display-container v-else label="ONT CRM">
            <v-alert
              outlined
              type="warning"
            >
              Es konnte kein ONT mit der Seriennummer <strong>{{ ontId }}</strong> im CRM gefunden werden!
            </v-alert>
          </display-container>
          <display-container v-if="connectedContract !== null" label="Aktuell verknüpfter Vertrag">
            <v-alert
              v-if="connectedContract.deactiveDate === null"
              outlined
              type="warning"
            >
              Der Vertrag hat <strong>kein</strong> Deaktivierungsdatum, daher konnte der ONT nicht automatisch dem neuen Vertrag zugewiesen werden.
            </v-alert>
            <v-alert
              v-else-if="connectedContract.deactiveDate !== null && parseDate(connectedContract.deactiveDate) > new Date()"
              outlined
              type="warning"
            >
              Das Deaktivierungsdatum befindet sich in der Zukunft, daher konnte der ONT nicht automatisch dem neuen Vertrag zugewiesen werden.
            </v-alert>
            <div class="details">
              <data-display label="Kundennummer">
                {{ connectedContract.customerId }}
              </data-display>
              <data-display label="Vertragsnummer">
                {{ connectedContract.contractId }}
              </data-display>
              <data-display label="Name">
                {{ connectedContract.addressShortDto.nameAndCompany }}
              </data-display>
              <data-display label="Adresse">
                {{ connectedContract.addressShortDto.compactAddress }}
              </data-display>
              <data-display label="Aktivierungsdatum">
                {{ convertDate(connectedContract.activeDate) }}
              </data-display>
              <data-display label="Deaktivierungsdatum">
                {{ convertDate(connectedContract.deactiveDate) }}
              </data-display>
            </div>
          </display-container>
        </div>
        <div v-else class="my-8">
          <span>Daten werden geladen</span>
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </div>
        <div class="margin-bottom-2 align-buttons-right">
          <v-btn
            :disabled="!claimed || isLoading"
            color="primary"
            @click="completeTaskDialog = true"
          >
            Aufgabe schließen
          </v-btn>
        </div>
      </div>
      <v-dialog v-model="completeTaskDialog" width="20vw">
        <v-card>
          <v-card-text class="pt-6">
            <p>
              Soll die Aufgabe beendet werden? Dadurch läuft der Prozess normal weiter, es werden keine Daten durch SPOT geändert.
            </p>
            <v-spacer></v-spacer>
            <div class="dialog-buttons">
              <v-btn
                small
                color="secondary"
                class="align-center"
                @click="completeTaskDialog = !completeTaskDialog"
              >
                Abbrechen
              </v-btn>
              <v-btn
                small
                color="primary"
                class="align-center"
                @click="completeTask"
              >
                Ja
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';

export default {
  name: 'ActionValidateOrderOntNumber',
  components: {
    TaskBlocker: () => import('@/task/TaskBlocker'),
    DisplayContainer: () => import('@/components/elements/DisplayContainer'),
    DataDisplay: () => import('@/components/elements/DataDisplay.vue'),
    ProjectDetails: () =>
      import('@/components/constructionProjects/ProjectDetails')
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    taskId: null,
    isLoading: true,
    ontId: null,
    crmOnt: null,
    connectedContract: null,
    completeTaskDialog: false,
  }),
  computed: {
    username() {
      return this.$store.getters.getUsername;
    },
    claimed() {
      return this.data.assignee === this.username;
    },
    camundaAdmin() {
      return this.$auth.user().userRoles.includes('camunda-admin');
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (this.taskId === null || this.taskId !== newVal.id) {
          this.taskId = newVal.id;
          setTimeout(this.loadTaskData);
        }
      }
    }
  },
  methods: {
    loadTaskData: async function () {
      this.isLoading = true;
      await this.data.variables.then((data) => {
        this.ontId = data.order.value.ontNumber;
      });

      HTTP.get('/orderTask/ontValidationDetails/' + this.data.id)
        .then(({ data }) => {
          this.crmOnt = data.varioResourceFound ? data.ontCpeDevice : null;
          this.connectedContract = data.assignedContract ? data.assignedContract : null;
        })
        .catch(({ response }) => {
          this.showToast(
            `Fehler beim laden der Prozessinformationen HTTP Status Code ${response.status}`
          );
        }).finally(() => {
          this.isLoading = false;
        });
    },
    showToast: function (message, type = 'error') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    parseDate: function (date) {
      return new Date(Date.parse(date));
    },
    convertDate: function (date) {
      if (!date) return '-';
      return new Date(Date.parse(date)).toLocaleDateString('de-DE');
    },
    completeTask: function () {
      HTTP.post(`/engine/default/task/${this.data.id}/complete`, {
        validateOrderOntNumberTask: this.username
      })
        .then(() => {
          this.$store.commit('triggerTasksRefresh');
          this.$emit('onClose');
        })
        .catch(() =>
          this.showToast('Die Aufgabe konnte nicht erfolgreich abgeschlossen werden!')
        );
    }
  }
};
</script>

<style scoped>
.details {
  display: grid;
  grid-template-columns: calc(50% - var(--goe-spacing-0_5)) calc(
      50% - var(--goe-spacing-0_5)
    );
  grid-gap: var(--goe-spacing-1);
}

.dialog-buttons :first-child {
  margin-right: 5px;
}
</style>
